import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

class NotFoundPage extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="404: Not Found" />
        <div className="flex flex-col ">
          <h1 className="text-indigo-700 text-8xl font-black -mb-10">404</h1>
          <p className="ml-10">That's an error.</p>
          <p className="ml-10">We couldn't find that page :(</p>
        </div>
        {/* <p>не найдено</p> */}
      </Layout>
    )
  }
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
